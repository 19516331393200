import React, { useState } from "react";
import Cart from "./Cart";

const News = () => {
    
    const [isEventsSelected, setIsEventSelected] = useState(true);

    return(
        <section ide="news" className="news">
            <div className="news__banner">
                {/* <h1 className="news__title">NOVEDADES</h1> */}
            </div>
            <div className="news__section-selector">
                <button className={`${isEventsSelected ? "news__section-selector--selected" : ""}`} onClick={() => setIsEventSelected(true)}>EVENTOS</button>
                <button className={`${!isEventsSelected ? "news__section-selector--selected" : ""}`} onClick={() => setIsEventSelected(false)}>NOTICIAS</button>
            </div>
             
            <div className="cart-container">
                {isEventsSelected ? <Cart tittle="Ejemplo" url="recurso1.png" description="some text" /> : ""}
            </div>
            
        </section>
    )
}

export default News;