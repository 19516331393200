import React from "react";
import FacebookButton from "./media/FacebookButton";
import WhatsappButton from "./media/WhatsappButton";
import InstagramButton from "./media/InstagramButton";
import YoutubeButton from "./media/YoutubeButton";
import DataFiscal from "./media/DataFiscal";

const Footer = () => {

    const space = '%0D%0A';
    const whatsappText = `Hola Propiedad Protegida.${space}Quiero hacer una consulta comercial:`;

    return(
        <section id="footer" className="footer">
            <div className="footer__background">
                <div className="footer__contact">
                    <p><b className="letBlueHighlight">Contacto:</b><br />
                        <b className="letBlueHighlight">Correo:</b> ventas@propiedadprotegida.com.ar<br />
                        <b className="letBlueHighlight">Teléfono fijo:</b> 0810-888-9035<br />
                        <b className="letBlueHighlight">WhatsApp:</b> +54 9 3413718525<br />
                    </p>
                </div>
                <div className="footer__data">
                    <p><b className="letBlueHighlight">Horario de atención:</b> <br />
                     De lunes de viernes de 09:00 a 17:00 horas <br/>
                     BV. Rondeau 4173, Rosario, Santa Fe.
                    </p>
                </div>
            </div>
            <div className="footer__media">
                <WhatsappButton href={"https://api.WhatsApp.com/send?phone=5493413718525&text=" + whatsappText + "&type=phone_number&app_absent=0"} class='footer__icon' width='32px' height='32px' />
                <FacebookButton href="https://www.facebook.com/propiedadprotegidarosario" class='footer__icon' width='32px' height='32px'/>
                <InstagramButton href="https://www.instagram.com/propiedadprotegida/" class='footer__icon' width='32px' height='32px'/>
                <YoutubeButton href='https://www.youtube.com/@PropiedadProtegida' class='footer__icon' width='32px' height='32px' />
                <DataFiscal />
            </div>
        </section>
    )
}

export default Footer;