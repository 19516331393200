import React, { useState } from "react";
import WhatsappButton from "./media/WhatsappButton";

const TechnicalSupport = () => {

    const [technicalForm, setTechnicalForm] = useState({
        razonSocial: "",
        name: "",
        phone: "",
        email: "",
        city: "",
        province: "",
        model: "",
        serialNumber: "",
        ticketNumber: "",
        ticketDate: "",
        ip: "",
        accesories: "",
        user: "",
        password: "",
        description: "",
    });

    const handleFormChange = (event, labelName) => {
        setTechnicalForm((prevState) => ({
            ...prevState,
            [labelName]: event.target.value
        }));
    }
    const handleTodayDate = () => {
        let today = new Date().toISOString().slice(0, 10)
        return today;
    }

    const space = '%0D%0A';
    const whatsappTechnicalText = `Hola Propiedad Protegida.${space}Quiero hacer una consulta técnica:`;
    
    const handleSubmit = (event) => {
        event.preventDefault();
        let space = '%0D%0A'
        let whatsappText = "*DATOS DEL CLIENTE*" + space + "*Razón social:* " + technicalForm.razonSocial + space + "*Nombre y Apellido:* " + technicalForm.name + space +"*Número de teléfono:* " + technicalForm.phone + space + "*Email:* " + technicalForm.email + space + "*Localidad:* " + technicalForm.city + space + "*Provincia:* " + technicalForm.province + space + space + "*DATOS DEL PRODUCTO*" + space + "*Modelo:* " + technicalForm.model + space + "*Número de serie:* " + technicalForm.serialNumber + space + "*Número de factura o comprobante:* " + technicalForm.ticketNumber + space + "*Fecha de comprobante:* " + technicalForm.ticketDate + space + "*IP configurada:* " + technicalForm.ip + space + "*Accesorios a entregar* " + technicalForm.accesories + space + "*Usuario configurado:* " + technicalForm.user + space + "*Contraseña configurada:* " + technicalForm.password + space + "*Descripción de la falla:* " + technicalForm.description;
        
        window.open("https://api.whatsapp.com/send/?phone=543416910379&text=" + whatsappText + "&type=phone_number&app_absent=0");
    }

    return(
        <section className="technical_support">

            <div className="technical_support__background"> </div>
            <h2 className="technical_support__tittle">DEPARTAMENTO TÉCNICO</h2>
            <div className="technical_support__paragraph">
                <p>
                    <b className="letBlueHighlight">Contacto:</b> <br />
                    <b className="letBlueHighlight">Correo:</b> tecnica@propiedadprotegida.com.ar <br />
                    <b className="letBlueHighlight">WhatsApp:</b> +54 9 3416910379 <br />
                </p>
                <WhatsappButton href={'https://api.whatsapp.com/send/?phone=543416910379&text=' + whatsappTechnicalText + '&type=phone_number&app_absent=0'} class='technical_support__WhatsAppButton' width='36px' text='Soporte' />
            </div>

            <h3 className='technical_support__subtitle'>MANUALES</h3>
            <article id='manuales' className='technical_support__manuals'>
                <p>Link para acceder a los manuales de los productos comercializados en nuestro local:</p>
                <a className='' href='https://drive.google.com/drive/folders/124xvZoqysBcUNcP9OgjfojHjxQSqUAop?usp=sharing' target='_blanck'>Click aquí</a>
            </article>

            <h3 className='technical_support__subtitle technical_support__subtitle--margin-bottom'>FORMULARIO DE SOLICITUD DE RMA</h3>
            <form className="technical_support__form">
                <div className="technical_support__datos">
                    <h3>DATOS DEL REMITENTE:</h3>
                    <label> Razón social: <br /><input type="text" value={technicalForm.razonSocial} placeholder='EMPRESA SRL'  onChange={(event) => handleFormChange(event, "razonSocial")} /></label>
                    <label> Nombre y apellido: <br /><input type="text" value={technicalForm.name} placeholder='Nombre Apellido' onChange={(event) => handleFormChange(event, "name")} /></label>
                    <label> Teléfono de contacto: <br /><input type="number" value={technicalForm.phone} placeholder='3416910379' onChange={(event) => handleFormChange(event, "phone")} /></label>
                    <label> Correo electrónico: <br /><input type="email" placeholder="example@email.com" value={technicalForm.email} required onChange={(event) => handleFormChange(event, "email")} /></label>
                    <label> Localidad: <br /><input type="text" value={technicalForm.city} placeholder='Rosario' onChange={(event) => handleFormChange(event, "city")} /></label>
                    <label> Provincia: <br /><input type="text" value={technicalForm.province} placeholder='Santa Fe' onChange={(event) => handleFormChange(event, "province")} /></label>
                </div>
                <div className="technical_support__datos">
                    <h3>DATOS DEL PRODUCTO:</h3>
                    <label> Marca y modelo: <br /><input type="text" value={technicalForm.model} placeholder='DAHUA XVR1B04-I' onChange={(event) => handleFormChange(event, "model")} /></label>
                    <label> Número de serie: <br /><input type="text" value={technicalForm.serialNumber} onChange={(event) => handleFormChange(event, "serialNumber")} /></label>
                    <label> Nº de factura o comprobante: <br /><input type="text" value={technicalForm.ticketNumber} onChange={(event) => handleFormChange(event, "ticketNumber")} /></label>
                    <label> Fecha de compra: <br /><input type="date" value={technicalForm.ticketDate} min='2010-01-01' max={handleTodayDate()} onChange={(event) => handleFormChange(event, "ticketDate")} /></label>
                    <label> IP: <br /><input type="text" value={technicalForm.ip} placeholder='192.168.0.100' onChange={(event) => handleFormChange(event, "ip")} /></label>
                    <label> Accesorios: <br /><input type="text" value={technicalForm.accesories} placeholder='Fuente, disco, mouse, etc' onChange={(event) => handleFormChange(event, "accesories")} /></label>
                    <label> Usuario: <br /><input type="text" value={technicalForm.user} placeholder='admin' onChange={(event) => handleFormChange(event, "user")} /></label>
                    <label> Contraseña: <br /><input type="text" value={technicalForm.password} placeholder='contraseña para usuario admin' onChange={(event) => handleFormChange(event, "password")} /></label>
                </div>
                <label className="technical_support__textarea"> Descripción de la falla: <br /><textarea type="text" value={technicalForm.description} placeholder='No lee el disco rígido' onChange={(event) => handleFormChange(event, "description")} /></label>
                <button type="button" value="Enviar formulario" onClick={(event) => handleSubmit(event)}>Enviar formulario</button>
                <p className="technical_support__advice">
                    Para la recepción de todas las RMA deberán entregarse los equipos completos, con sus accesorios, en su caja correspondiente, la cual no debe tener ninguna marca o escritura de ningún tipo ni papeles pegados en ella, así como todos sus films, bolsas y plásticos correspondientes. Dichos faltantes podrían condicionar la garantía de las mismas.
                </p>
            </form>
            
            
            
            
        </section>
    )
}

export default TechnicalSupport;