import datafiscal from "../../img/datafiscal.jpg"


const DataFiscal = () => {

    return (
        <a href="http://qr.afip.gob.ar/?qr=-QWEw-jXXhs24uAvelJYVw,," target="_blanck" className='footer__icon footer__icon--datafiscal' width='32px'>
            <img src={datafiscal} alt='Imagen de Data fiscal' border="0" />
        </a>
    )
}

export default DataFiscal;