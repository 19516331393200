// import logo from '../public/Recurso3.png';
import React, { useState } from 'react';
import './App.css';
import Header from "./components/Header.jsx"
import Welcome from "./components/Welcome.jsx"
import TechnicalSupport from "./components/TechnicalSupport"
import News from './components/News';
import Contact from './components/Contact';




function App() {

  const [render, setRender] = useState("inicio");
  const handleRender = (willRender) => {
    setRender(willRender);
  }

  return (
    <div className="App">
      <Header render={render} handleRender={handleRender} setRender={setRender} />
      
      {render === "inicio" &&
        <>
          <Welcome render={render}/>
        </>
      }
      {render === "technicalSupport" &&
        <>
          <TechnicalSupport />
        </>
      }
      {render === "novedades" &&
        <>
          <News />
        </>
      }

      <Contact render={render} />
        
    </div>
  );
}

export default App;
