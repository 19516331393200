import React from "react";
import Footer from "./Footer";
import WhatsappButton from "./media/WhatsappButton";
import InstagramButton from "./media/InstagramButton";
import FacebookButton from "./media/FacebookButton";
import YoutubeButton from "./media/YoutubeButton";
import DataFiscal from "./media/DataFiscal";


const Contact = (props) => {

    const space = '%0D%0A';
    const whatsappText = `Hola Propiedad Protegida.${space}Quiero hacer una consulta comercial:`;
    const whatsappTechnicalText = `Hola Propiedad Protegida.${space}Quiero hacer una consulta técnica:`;
    const whatsappMonitoreoText = `Hola Propiedad Protegida.${space}Quiero hacer una consulta sobre monitoreo:`;
    const whatsappAdministrationText = `Hola Propiedad Protegida.${space}Quiero hacer una consulta administrativa:`;


    if(props.render==="contacto"){
        return(
            <section id="contact-section" className="contact-section">
                <h1 className="contact-section__title">¡Contactarnos es sencillo!</h1>
                <div className='contact-section__grid-container'>

                    <article className="contact-section__1">
                        <h2 ><b className="letBlueHighlight">ATENCIÓN AL PÚBLICO:</b></h2>
                        <p><b className="letBlueHighlight">Correo:</b> ventas@propiedadprotegida.com.ar <br /> <b className="letBlueHighlight">WhatsApp:</b> +54 9 3413718525</p>
                        <p><b className="letBlueHighlight">Teléfono fijo:</b> 0810-888-9035</p>
                        <WhatsappButton href={"https://api.WhatsApp.com/send?phone=5493413718525&text=" + whatsappText + "&type=phone_number&app_absent=0" } class='contact-section__WhatsAppButton' width='16px' text='Sector Ventas' />
                    </article>

                    <article className="contact-section__2">
                        <h2 ><b className="letBlueHighlight">SOPORTE TÉCNICO:</b></h2>
                        <p><b className="letBlueHighlight">Correo:</b> tecnica@propiedadprotegida.com.ar <br /> <b className="letBlueHighlight">WhatsApp:</b> +54 9 3416910379
                        </p>
                        <WhatsappButton href={"https://api.whatsapp.com/send/?phone=543416910379&text=" + whatsappTechnicalText + "&type=phone_number&app_absent=0"} class='contact-section__WhatsAppButton' width='16px' text='Soporte Técnico' />
                    </article>

                    <article className="contact-section__3">
                        <h2 ><b className="letBlueHighlight">MONITOREO:</b></h2>
                        <p><b className="letBlueHighlight">Correo:</b> monitoreo@propiedadprotegida.com.ar <br /> <b className="letBlueHighlight">WhatsApp:</b> +54 9 3416172846
                        </p>
                        <WhatsappButton href={'https://api.WhatsApp.com/send?phone=5493416172846&text=' + whatsappMonitoreoText + '&type=phone_number&app_absent=0'} class='contact-section__WhatsAppButton' width='16px' text='Monitoreo' />
                    </article>

                    <article className="contact-section__4">
                        <h2 ><b className="letBlueHighlight">ADMINISTRACIÓN:</b></h2>
                        <p><b className="letBlueHighlight">Correo:</b> administracion@propiedadprotegida.com.ar <br /> <b className="letBlueHighlight">WhatsApp:</b> +54 9 3413127079
                        </p>
                        <WhatsappButton href={'https://api.WhatsApp.com/send?phone=5493413127079&text=' + whatsappAdministrationText + '&type=phone_number&app_absent=0'} class='contact-section__WhatsAppButton' width='16px' text='Administración' />
                    </article>
                    
                    <article className="contact-section__5">
                        <p>
                            <b className="letBlueHighlight">Horario de atención:</b> <br />
                            De lunes de viernes de 09:00 a 17:00 horas <br />
                            BV. Rondeau 4173, Rosario, Santa Fe.
                        </p>
                        <FacebookButton href="https://www.facebook.com/propiedadprotegidarosario" class='footer__icon' width='32px' height='32px' />
                        <InstagramButton href="https://www.instagram.com/propiedadprotegida/" class='footer__icon' width='32px' height='32px' />
                        <YoutubeButton href='https://www.youtube.com/@PropiedadProtegida' class='footer__icon' width='32px' height='32px' />
                        <DataFiscal />
                    </article>

                    <iframe className='contact-section__map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d66950.99173103647!2d-60.700458514866455!3d-32.895764009318704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b653c000d99f69%3A0x66763dc2a4fa370f!2sBlvd.%20Rondeau%204173%2C%20S2000%20Rosario%2C%20Santa%20Fe!5e0!3m2!1ses!2sar!4v1676396771450!5m2!1ses!2sar" title='Mapa con la ubicación de Propiedad Protegida en Rosario' width="500" height="450" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                
                <div className='contact__background'></div>
            </section>
        )
    }   else {
        return <Footer />;
    }

    
}

export default Contact;