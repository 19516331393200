import { useState, useRef, useEffect } from "react";


const Header = (props) => {

  const [headerColor, setHeaderColor] = useState(false);
  const [openModal, toggleModal] = useState(false);
  const modalRef = useRef(null);

  const changeHeaderBackground = () => {
    if (window.pageYOffset > (window.innerHeight * 0.1) && window.innerWidth > 640) { //| (window.innerWidth < 1100 && window.pageYOffset > (window.innerHeight*0.3))){//|| props.render === "technicalSupport"
      setHeaderColor(true);
    } else {
      setHeaderColor(false);
    }
  }

  const technicalSupportClickEvent = () => {
    props.handleRender("technicalSupport");
    setHeaderColor(false);
    toggleModal(false);
    window.scrollTo(0, 0);
  }
  const inicioClickEvent = () => {
    props.handleRender("inicio");
    setHeaderColor(false);
    toggleModal(false);
    window.scrollTo(0, 0);
  }
  const contactoClickEvent = () => {
    props.handleRender("contacto");
    // setHeaderColor(true);
    toggleModal(false);
    window.scrollTo(0, 0);
  }

  // const novedadesClickEvent = () => {
  //   props.handleRender("novedades"); 
  //   // setHeaderColor(true);
  //   window.scrollTo(0, 0);
  // }

  

  window.addEventListener("scroll", changeHeaderBackground);


  /*Add event listeners when component mounts */
  useEffect(() => {
    /* Close the Modal when the user clicks outside of it */
    const closeModal = (event) => {
      if (modalRef.current && modalRef.current.contains(event.target)) {
        return;
      }
      toggleModal(false);
    };

    /* Open and Close the navbar modal when user swipes - start
       used native Javascript for this functionality
    */
    let initialPosition = 0;
    let moving = false;
    const gestureStart = (event) => {
      initialPosition = event.touches[0].clientX;
      moving = true;
    }
    const gestureMove = (event) => {
      if (moving) {
        let currentPosition = event.touches[0].clientX;
        let diff = currentPosition - initialPosition;

        if(diff > 100){
          toggleModal(false)
          initialPosition = currentPosition;
        } else if(diff < -100){
          toggleModal(true)
          initialPosition = currentPosition;
        }
      }
    }
    const gestureEnd = () => {
      moving = false;
    }
    /* Open and Close the navbar modal when user swipes - end*/

    
    // document.addEventListener("mousedown", closeModal);
    document.addEventListener('touchstart', gestureStart);
    document.addEventListener('touchmove', gestureMove);
    document.addEventListener('touchend', gestureEnd);

    return () => document.removeEventListener("mousedown", closeModal);
  }, []);




  return (
    <header className={headerColor ? "header header--background" : "header"}>
      <img className="header__logo" src="recurso1.png" onClick={inicioClickEvent} alt="Logo de la empresa" />
      <button id='header__hamburger-button' className={'header__hamburger-button'} onClick={() => toggleModal(!openModal)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width='16px'>
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
        <span>MENÚ</span>
      </button>

      <nav id ='navbar' className={openModal ? 'navbar navbar--opened' : 'navbar navbar--closed'} ref={modalRef}>
        <button className='navbar__border-right navbar__left navbar__button' onClick={inicioClickEvent}>Inicio <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width='16px'>
          <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
        </svg>
        </button>

        {/* <a className='navbar__border-right' onClick={novedadesClickEvent}>Novedades</a> */}

        <button width='2rem' className='navbar__border-right navbar__button' onClick={technicalSupportClickEvent}>Soporte
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width='16px'>
            <path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
          </svg>
        </button>

        <button className="navbar__right navbar__button" onClick={contactoClickEvent} >Contacto
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width='16px'>
            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
          </svg>
        </button>

        <a className="header__logIn" href="https://propiedadprotegida.mercadoshops.com.ar/" target="_blanck">Tienda
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16px" >
            <path d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z" />
          </svg>
        </a>

        <a className='header__logIn' href="https://allwayserp.com/awav2/public/login/prop_proteg" target="_blanck">Ingresar
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width='16px'>
            <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
          </svg>
        </a>

      {/* <button className="header__closeModal" onClick={() => toggleModal(!openModal)}>X Cerrar</button> */}

      </nav>

    </header>
  )
}

export default Header;