import React from "react";

const Cart = (props) => {


    return(
        <div className="cart">
            <h2>{props.tittle}</h2>
            <img src={props.url} alt={props.altText}></img>
            <p>{props.description}</p>
        </div>
    )
}

export default Cart;