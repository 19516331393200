import React from "react";
import logo from "../img/recurso4.png"
import Presentation from "./Presentation";

const Welcome = (props) => {


    return(
        <section className="welcome">
            <div className="welcome__background"></div>
              <img className="welcome__img" alt='logo de la empresa' src={logo} width='300px' height='300px'></img>
              {/* <h1 className="welcome__title">EXPERTOS EN SEGURIDAD ELECTRÓNICA</h1>             */}
            <Presentation />
        </section>

    )
}

export default Welcome;