import React, {useState, useRef, useEffect} from "react";
import dahuaBanner from '../img/logo_marcas/dahua.png';
import garnetBanner from '../img/logo_marcas/garnet.png';
import hikvisionBanner from '../img/logo_marcas/hikvision.png';
import dscBanner from '../img/logo_marcas/dsc.png';
import anvizBanner from '../img/logo_marcas/anviz.png';
import marshallBanner from '../img/logo_marcas/marshall.png';
import takexBanner from '../img/logo_marcas/takex.png';
import zktecoxBanner from '../img/logo_marcas/zkteco.png';
import segBanner from '../img/logo_marcas/seg.png'
import cmrBanner from '../img/logo_marcas/cmr.png'
import netioBanner from '../img/logo_marcas/netio.png'
// import dxcontrolBanner from '../img/logo_marcas/dxcontrol.png'
import ezvizBanner from '../img/logo_marcas/ezviz.png'
// import imouBanner from '../img/logo_marcas/imou.png'
import gralfBanner from '../img/logo_marcas/gralf.png'



const Carousel = (props) => {
    const [index, setIndex] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true) 
    const bannerArray = [dahuaBanner,  garnetBanner, hikvisionBanner, dscBanner, anvizBanner, marshallBanner, takexBanner, zktecoxBanner, segBanner, cmrBanner, netioBanner, ezvizBanner, gralfBanner];
    const length = bannerArray.length;

    
    const handlePrevious = () => {
        const aux = index - 1;
        setIndex (aux < 0 ? length - 1 : aux);
        handleSelectedDot(aux < 0 ? length -1 : aux);
    };

    const handleNext = () => {
        const aux = index + 1;
        setIndex(aux >= length ? 0 : aux);
        handleSelectedDot(aux >= length ? 0 : aux);
    };

    const handleDots = (dot) => {
        setIndex(dot);
        handleSelectedDot(dot);
    }

    const handleSelectedDot = (dot) => {
        let dotArray = document.getElementsByClassName("dot");
        for (let i = 0; i < bannerArray.length; i++) {
            dotArray[i].classList.remove("selected");
        }
        dotArray[dot].classList.add("selected");
    }


    const autoPlayRef = useRef();

    useEffect(() => {
        autoPlayRef.current = handleNext
    })

    useEffect(() => {
        const play = () => {
            if(autoPlay) {autoPlayRef.current()} 
        }

        const interval = setInterval(play, props.autoPlay * 1000)
        return () => clearInterval(interval)
    }, [props.autoPlay, autoPlay])

    return (
        <div className="carousel" onMouseOver={() => setAutoPlay(false)} onTouchStart={() => setAutoPlay(false)} onMouseOut={() => setAutoPlay(true)} onTouchEnd={() => setAutoPlay(true)}>
            
            <img id='banner' className="carousel_banner" src={bannerArray[index]} width="100%" alt={"banner número "+index} ></img> 
            {/* <div className="carousel__slider"> */}
                <button className="carousel_button carousel_button--left" onClick={handlePrevious}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1rem"> <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg> </button>
                    <div className="carousel_dots">
                        {bannerArray.map((value, dotIndex) => (
                           dotIndex === 0 ? <i className="dot selected" key={dotIndex} onClick={() => handleDots(dotIndex)}></i> :                             
                            <i className="dot" key={dotIndex} onClick={() => handleDots(dotIndex)}></i>
                        ))}
                    </div>
                <button className="carousel_button carousel_button--right" onClick={handleNext}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1rem"> <path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg> </button>
            {/* </div> */}
            
        </div>
    );
};

export default Carousel;

