import React from "react";
import Carousel from './Carousel';
import afip from '../img/afip.jpg'

const Presentation = () => {

    return (
        <section className="presentation">
            <p className="presentation__text">
                Somos distribuidores mayoristas de sistemas de seguridad electrónica con 15 años de experiencia.
            </p>
            <p className="presentation__text">
                Nuestro local de 400 m<sup>2</sup> dispone de más de 1000 ítems dispuestos en góndolas para el autoabastecimiento de los clientes, además de la posibilidad de proveer productos especiales en menos de 24 hs. El mismo está estratégicamente ubicado en la Zona Norte de Rosario, Santa Fe, abasteciendo desde allí a todo el país.
            </p>
            <p className="presentation__text">
                Nos enfocamos en el análisis de vulnerabilidades, la confección de proyectos y la provisión de la mercadería necesaria para instalar sistemas de alarmas de instrusión y de incendio, videovigilancia para hogares, comercios e industrias, videovigilancia con geolocalización para camiones, colectivos y cualquier tipo de vehículos, conectividad, controles de acceso,  electrificadores, automatización de portones, monitoreo mayorista, y más. Poseemos una gran experiencia asesorando para proteger hogares, comercios, empresas, industrias, bancos, barrios privados y municipios.
            </p>
            <p className="presentation__text">
                Somos distribuidores oficiales de las principales marcas nacionales e internacionales del rubro:
            </p>
            <Carousel autoPlay={2}/>
            <h3 className="presentation__text">Conocé nuestro local:</h3>

            <iframe className="presentation__video" height="500px" src="https://www.youtube.com/embed/tUceR7JxW8U" title="YouTube video player" alt='ideo de presentación en youtube' frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

        </section>
    )
}

export default Presentation;